import React from "react";

function GlobalHorizontalDisplay({ title, text }) {
  return (
    <div className="flex justify-between items-center py-1 pr-2">
      <p className="text-xs text-gray-500 font-semibold">{title}</p>
      <p className="text-xs text-gray-500 w-1/2 text-left">{text}</p>
    </div>
  );
}

export default GlobalHorizontalDisplay;
