/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FabButton from "../components/global/FabButton";
import SidePanel from "../components/global/SidePanel";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../components/global/TextInput";
import Button from "../components/global/Button";
import { ToastContainer, toast } from "react-toastify";
import CustomDateFormat from "../components/global/CustomDateFormat";
import HeaderText from "../components/global/HeaderText";
import {
  closeAddSideModal,
  fetchingDocumentsAction,
  openAddSideModal,
  uploadDocumentsAction,
} from "../redux/Index";
import Loader from "../components/global/Loader";
import DataTable from "../components/datatable/DataTable";
import { ADMIN_BASE_URL } from "../redux/api";

function Documents() {
  const dispatch = useDispatch();

  const documentsState = useSelector((state) => state.documents);

  const [data, setData] = useState(null);
  const [ID, setID] = useState(null);
  const [BANK, setBANK] = useState(null);
  const [CLEARANCE, setCLEARANCE] = useState(null);

  useEffect(() => {
    dispatch(fetchingDocumentsAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(documentsState?.documents);
  }, [documentsState?.documents]);

  const openFile = (link) => {
    const url = ADMIN_BASE_URL + link;
    console.log(url);
    window.open(url, "_blank").focus();
  };

  const headers = ["Item", "Created on", "View"];
  let tableRows = [];
  if (data?.length) {
    for (let i = 0; i < data?.length; i++) {
      const element = data[i];
      tableRows.push({
        name: element.name,
        created_at: <CustomDateFormat>{element.created_at}</CustomDateFormat>,
        view: (
          <i
            className="bx bx-link-external text-lg cursor-pointer"
            onClick={() => openFile(element.document?.file)}
          ></i>
        ),
      });
    }
  }

  const [inputValues, setInputValues] = useState({
    ID: "",
    BANK: "",
    CLEARANCE: "",
  });

  const types = ["ID", "BANK", "CLEARANCE"];

  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    setInputValues({ ...inputValues, [name]: value });
    if (name === "ID") {
      setID(e.target.files[0]);
    }
    if (name === "BANK") {
      setBANK(e.target.files[0]);
    }
    if (name === "CLEARANCE") {
      setCLEARANCE(e.target.files[0]);
    }
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      inputValues.ID?.trim() !== "" ||
      inputValues.CLEARANCE?.trim() !== "" ||
      inputValues.BANK?.trim() !== ""
    ) {
      const formData = new FormData();

      formData.append("ID", ID);
      formData.append("CLEARANCE", CLEARANCE);
      formData.append("BANK", BANK);

      dispatch(uploadDocumentsAction(formData)).then((status) => {
        if (status) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Documents upload was successful");
        } else {
          setIsSubmit(false);
          toast.error("Failed to execute the request");
        }
      });
    }
  };

  let typeOptions = [{ value: "", label: "--Select type--" }];
  for (let i = 0; i < types?.length; i++) {
    const element = types[i];
    typeOptions.push({
      value: element,
      label: element,
    });
  }

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setInputValues({
      ID: "",
      BANK: "",
      CLEARANCE: "",
    });
  };

  if (documentsState.fetchingDocuments) {
    return <Loader />;
  }

  const veryfy = () => {
    window.location.href = "/auth";
  };

  return (
    <div className="flex-1 h-full px-2 pt-3">
      {/* header information */}
      <HeaderText title="Document uploads" />
      <FabButton onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />

      {data?.length ? (
        <Button
          onClick={veryfy}
          text={"Go to Homepage"}
          bgColor={"bg-secondary"}
        />
      ) : null}

      {/* display sidepanel */}
      <SidePanel width="30%" title="Stores" subtitle={""}>
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="Upload ID - Front"
            name="ID"
            type="file"
            value={inputValues.ID}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Clearance Doc"
            name="CLEARANCE"
            type="file"
            value={inputValues.CLEARANCE}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Upload Bank Doc"
            name="BANK"
            type="file"
            value={inputValues.BANK}
            onChange={handleChange}
          />
          {inputValues.ID && inputValues.BANK && inputValues.CLEARANCE ? (
            <Button
              text={isSubmit ? "Processing..." : "Upload"}
              disabled={isSubmit}
            />
          ) : null}
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

export default Documents;
