/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { useDispatch, useSelector } from "react-redux";
import { closeAddSideModal, openAddSideModal } from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";
import { Switch } from "@mui/material";
import CustomDateFormat from "../global/CustomDateFormat";
import {
  createStoreAction,
  fetchStoresAction,
  updateStoreAction,
} from "../../redux/store/storeActions";

function Stores() {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [stores, setStores] = useState(null);
  const dispatch = useDispatch();
  const storesState = useSelector((state) => state.stores);
  const userLocation = useSelector((state) => state.auth.userLocation);

  useEffect(() => {
    loadModules();
  }, []);

  const loadModules = () => {
    dispatch(fetchStoresAction());
  };

  useEffect(() => {
    setStores(storesState.stores);
  }, [storesState.stores]);

  const headers = [
    "Store name",
    "Created on",
    "Email address",
    "Telephone",
    "Till number",
    "Status",
    "Edit",
  ];
  let tableRows = [];
  if (stores?.length) {
    for (let i = 0; i < stores?.length; i++) {
      const element = stores[i];
      tableRows.push({
        name: element.business_name,
        created_at: <CustomDateFormat>{element.created_at}</CustomDateFormat>,
        account_email: element.email,
        account_phone_number: element.phone_number,
        till_number: element.till_number,
        status: (
          <Switch
            checked={element.is_active}
            onChange={() => toggleStoreStatus(element.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        edit: (
          <i
            className="bx bx-edit links text-[20px] cursor-pointer"
            onClick={() => editModuleDetails(element)}
          ></i>
        ),
      });
    }
  }

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        account_email: selectedItem?.email,
        account_phone_number: selectedItem?.phone_number,
        business_name: selectedItem?.business_name,
      });
    }
  }, [isEdit, selectedItem]);

  const toggleStoreStatus = (id) => {
    console.log(id);
  };
  const editModuleDetails = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const [inputValues, setInputValues] = useState({
    account_email: "",
    account_phone_number: "",
    business_name: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    inputValues.latitude = userLocation?.latitude;
    inputValues.longitude = userLocation?.longitude;

    if (
      inputValues.account_email?.trim() !== "" ||
      inputValues.account_phone_number?.trim() !== "" ||
      inputValues.business_name?.trim() !== ""
    ) {
      if (isEdit) {
        inputValues.id = selectedItem.id;
      }
      dispatch(
        isEdit ? updateStoreAction(inputValues) : createStoreAction(inputValues)
      ).then((status) => {
        if (status) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Operation was successful");
        } else {
          setIsSubmit(false);
          toast.error("Failed to execute the request");
        }
      });
    }
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setInputValues({
      account_email: "",
      account_phone_number: "",
      business_name: "",
    });
  };

  if (storesState.fetchingStores) {
    return <Loader />;
  }

  return (
    <div className="flex-1 h-full">
      <FabButton text={"Add Store"} onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel
        width="30%"
        title="Stores"
        subtitle={isEdit ? "Update a Store" : "Create a Store"}
      >
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          {!isEdit && (
            <TextInput
              required="required"
              label="Business name"
              name="business_name"
              type="text"
              value={inputValues.business_name}
              onChange={handleChange}
            />
          )}
          <TextInput
            required="required"
            label="Email address"
            name="account_email"
            type="email"
            value={inputValues.account_email}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Telephone number"
            name="account_phone_number"
            type="numeric"
            value={inputValues.account_phone_number}
            onChange={handleChange}
          />
          <Button
            text={isSubmit ? "Processing..." : isEdit ? "Update" : "Create"}
            disabled={isSubmit}
          />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

export default Stores;
