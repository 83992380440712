import EmployeesClient from "../../api/EmployeesClient";
import {
  CREATE_EMPLOYEE,
  INITIALIZE_EMPLOYEE_STATE,
  SET_EMPLOYEES,
  TOGGLE_FETCHING_EMPLOYEES,
  TOGGLE_EMPLOYEE_STATUS,
  UPDATE_EMPLOYEE,
} from "./employeesActionTypes";
// set the actions
export const initializeEmployeesState = () => {
  return {
    type: INITIALIZE_EMPLOYEE_STATE,
  };
};

export const toggleFetchingEmployees = (status) => {
  return {
    type: TOGGLE_FETCHING_EMPLOYEES,
    payload: status,
  };
};

export const setEmployees = (data) => {
  return {
    type: SET_EMPLOYEES,
    payload: data,
  };
};

export const createEmployee = (data) => {
  return {
    type: CREATE_EMPLOYEE,
    payload: data,
  };
};

export const updateEmployee = (data) => {
  return {
    type: UPDATE_EMPLOYEE,
    payload: data,
  };
};

export const toggleEmployeestatus = (data) => {
  return {
    type: TOGGLE_EMPLOYEE_STATUS,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchEmployeesAction() {
  return function action(dispatch) {
    dispatch(initializeEmployeesState);
    dispatch(toggleFetchingEmployees(true));

    return EmployeesClient.fetchEmployees()
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          const resp = response.data.data;
          const data = [];

          for (let i = 0; i < resp?.length; i++) {
            const element = resp[i];
            data.push({
              full_name: element?.client?.full_name,
              email_address: element?.client?.email_address,
              phone_number: element?.client?.phone_number,
              id: element?.id,
              status: element?.status,
              created_at: element?.created_at,
            });
          }
          dispatch(setEmployees(data));
          dispatch(toggleFetchingEmployees(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingEmployees(false));
      });
  };
}

export function createEmployeeAction(item) {
  return function action(dispatch) {
    return EmployeesClient.createEmployee(item)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          const element = response.data.data;
          const data = {
            full_name: element?.client?.full_name,
            email_address: element?.client?.email_address,
            phone_number: element?.client?.phone_number,
            id: element?.id,
            status: element?.status,
            created_at: element?.created_at,
          };
          dispatch(createEmployee(data));
          return true;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}

export function updateEmployeeAction(data) {
  return function action(dispatch) {
    return EmployeesClient.createEmployee(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateEmployee(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}

export function toggleEmployeeStatusAction(data) {
  return function action(dispatch) {
    return EmployeesClient.createEmployee(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(toggleEmployeestatus(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}
