import {
  CLEAR_AGENTS_STATE,
  SET_AGENTS,
  SET_AGENTS_ERROR,
  SET_AGENTS_SUCCESS,
  SET_SELECTED_AGENT,
  TOGGLE_FETCHING_AGENTS,
} from "./agentActionTypes";

// set the initial state
const initialState = () => {
  return {
    selectedAgent: {},
    fetchingAgents: false,
    agents: [],
    success: "",
    error: "",
  };
};

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_AGENTS_STATE:
      return {
        ...state,
        success: "",
        error: "",
        fetchingAgents: false,
      };
    /* falls through */
    case SET_SELECTED_AGENT:
      return {
        ...state,
        selectedAgent: action.payload,
      };
    /* falls through */
    case TOGGLE_FETCHING_AGENTS:
      return {
        ...state,
        fetchingAgents: action.payload,
      };
    /* falls through */
    case SET_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };
    /* falls through */
    case SET_AGENTS_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_AGENTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default agentReducer;
