import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Overlay from "../components/global/Overlay";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeToken } from "../redux/ApiClient";
import { fetchingDocumentsAction } from "../redux/Index";

function Layout() {
  const [showMenu, setShowMenu] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const dispatch = useDispatch();

  const documentsState = useSelector((state) => state?.documents);

  const [data, setData] = useState(documentsState.documents);

  useEffect(() => {
    dispatch(fetchingDocumentsAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(documentsState?.documents);
  }, [documentsState?.documents]);

  if (!data?.length) {
    window.location.href = "/documents";
  }

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 969) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    initializeToken();
    window.addEventListener("resize", handleResize);
  });

  // toggle the sidebar
  let toggleSidebar = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="h-screen flex flex-col">
      <Overlay toggleSidebar={toggleSidebar} showMenu={showMenu} />
      <Header toggleSidebar={toggleSidebar} setPromptOpen={setPromptOpen} />
      <div className="bg-gray-50 flex flex-1 overflow-x-hidden no-scrollbar mt-10">
        {!promptOpen && (
          <Sidebar toggleSidebar={toggleSidebar} showMenu={showMenu} />
        )}
        <div
          className={`${
            !isMobile ? "pl-20 w-full" : ""
          } p-3 flex flex-col flex-1`}
        >
          <div className={` flex-1`}>{<Outlet />}</div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state?.auth && state?.auth ? state?.auth : [],
  };
};

export default connect(mapStateToProps)(Layout);
