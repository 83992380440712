/* eslint-disable import/no-anonymous-default-export */

import clientsApi from "../redux/ClientsApi";

export default {
  fetchContacts() {
    return clientsApi.get("/client/device/info/frequent");
  },
  fetchContact(num) {
    return clientsApi.get(`/client/device/info/search?phone=${num}`);
  },
};
