import React from "react";

const AuthInput = (props) => {
  return (
    <div className="w-full bg-white flex flex-row items-center justify-between px-2 py-[10px] rounded-full space-x-2 align-middle mb-6">
      <span className="w-[6%]">{props.prefix && props.prefix}</span>
      <input
        className="bg-transparent flex-1 outline-none w-[90%]"
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        autoComplete="off"
        placeholder={props.placeholder}
        maxLength={props.maxLength ? props.maxLength : null}
      />
      <span className="w-[10%]">{props.suffix && props.suffix}</span>
    </div>
  );
};

export default AuthInput;
