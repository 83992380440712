import {
  CLEAR_WALLET_STATE,
  TOGGLE_FETCHING_WALLET_TRANSACTIONS,
  SET_WALLET_TRANSACTIONS,
  SET_SELECTED_TRANSACTION,
} from "./walletActionTypes";

// set the initial state
const initialState = () => {
  return {
    walletTransactions: [],
    fetchingWalletTransactions: {},
    selectedWalletTransaction: {},
  };
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_WALLET_STATE:
      return { ...state, selectedWalletTransaction: {} };

    case TOGGLE_FETCHING_WALLET_TRANSACTIONS:
      return { ...state, fetchingWalletTransactions: action.payload };

    case SET_WALLET_TRANSACTIONS:
      return { ...state, walletTransactions: [action.payload] };

    case SET_SELECTED_TRANSACTION:
      return { ...state, selectedWalletTransaction: action.payload };

    default:
      return state;
  }
};

export default walletReducer;
