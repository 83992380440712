import storage from "redux-persist/lib/storage";
import rootReducer from "../ReducerRoutes";
import {
    CLEAR_AUTH_STATE,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    REFRESH_TOKEN, REGENERATE_QR_CODE,
    REGISTER_SUCCESS,
    SET_USER_LOCATION,
    SIGNING_UP,
} from "./authActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    signingUp: false,
    userInfo: {},
    error: "",
    success: "",
    isLoggedIn: undefined,
    token: "",
    userLocation: {},
  };
};

// set the reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        success: "",
        error: "",
      };
      case REGENERATE_QR_CODE:
        const response = action.payload;
        const updateUserInfo = state.userInfo
        if (updateUserInfo.id)
          updateUserInfo.qr_code = response
         return {
          ...state,
           userInfo: updateUserInfo
        };
    /* falls through */
    case LOGIN_SUCCESS:
      const resp = action.payload.data.data;
      const data = {
        store: resp.store,
        status: resp.status,
        updated_at: resp.updated_at,
        created_at: resp.created_at,
        id: resp.id,
        is_active: resp.is_active,
        is_suspended: resp.is_suspended,
        email: resp.client?.email_address,
        full_name: resp.client?.full_name,
        phone_number: resp.client?.phone_number,
        business_name: resp.company?.business_name,
        qr_code: resp.company?.qr_code,
        till_number: resp.company?.till_number,
      };
      return {
        ...state,
        loading: false,
        userInfo: data,
        token: action.payload.data.token,
        error: "",
        success: action.payload.data.message,
        isLoggedIn: true,
      };
    /* falls through */
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };
    /* falls through */
    case SIGNING_UP:
      return {
        ...state,
        signingUp: action.payload,
      };
    /* falls through */
    case SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload,
      };
    /* falls through */
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.data,
        token: action.payload.token,
        success: action.payload.message,
        isLoggedIn: true,
      };
    /* falls through */
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    /* falls through */
    case LOGOUT_SUCCESS:
      state = rootReducer(undefined, {});
      storage.removeItem("persist:pgMerchant");
      localStorage.removeItem("persist:pgMerchant");
      return {
        loading: undefined,
        success: action.payload,
        error: "",
      };
    /* falls through */
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        success: "",
        error: action.payload,
      };
    /* falls through */
    case REFRESH_TOKEN:
      return {
        ...state,
        isLoggedIn: undefined,
      };
    /* falls through */

    default:
      return state;
  }
};

export default authReducer;
