import {
  CREATE_STORE,
  INITIALIZE_STORE_STATE,
  SET_STORES,
  TOGGLE_FETCHING_STORES,
  TOGGLE_STORE_STATUS,
  UPDATE_STORE,
} from "./storeActionTypes";

// set the initial state
const initialState = () => {
  return {
    fetchingStores: false,
    stores: [],
  };
};

// set the reducer
const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_STORE_STATE:
      return {
        ...state,
      };
    /* falls through */
    case TOGGLE_FETCHING_STORES:
      return {
        ...state,
        fetchingStores: action.payload,
      };
    /* falls through */
    case SET_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    /* falls through */
    case CREATE_STORE:
      return {
        ...state,
        stores: [...state.stores, action.payload],
      };
    /* falls through */
    case UPDATE_STORE:
      let newstores = [...state.stores];
      let foundIndex = newstores?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newstores[foundIndex] = action.payload;
      return {
        ...state,
        stores: newstores,
      };
    /* falls through */

    case TOGGLE_STORE_STATUS:
      return {
        ...state,
        stores: state.stores?.map((item) => {
          if (item.id === action.payload?.id) {
            return {
              ...item,
              status: action.payload.status,
            };
          }
          return item;
        }),
      };
    /* falls through */

    default:
      return state;
  }
};

export default storesReducer;
