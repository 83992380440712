import { SET_SELECTED_MERCHANT } from "./merchantActionTypes";


// set the initial state
const initialState = () => {
  return {
    selectedMerchant: {},
  };
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MERCHANT:
      return { selectedMerchant: action.payload };

    default:
      return state;
  }
};

export default merchantReducer;
