import {
  CLEAR_CONTACTS_STATE,
  SET_CONTACTS,
  SET_CONTACTS_ACTION_ERROR,
  SET_CONTACTS_ACTION_SUCCESS,
  SET_SELECTED_CONTACT,
  TOGGLE_FETCHING_A_CONTACT,
  TOGGLE_FETCHING_CONTACTS,
} from "./contactsActionTypes";

// set the initial state
const initialState = {
  contactsList: [],
  selectedContact: {},
  success: "",
  error: "",
  fetchingContacts: false,
  fetchingContact: false,
};

// set the reducer
const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CONTACTS_STATE:
      return {
        ...state,
        success: "",
        error: "",
      };
    /* falls through */

    case TOGGLE_FETCHING_CONTACTS:
      return {
        ...state,
        fetchingContacts: action.payload,
      };
    /* falls through */

    case TOGGLE_FETCHING_A_CONTACT:
      return {
        ...state,
        fetchingContact: action.payload,
      };
    /* falls through */

    case SET_CONTACTS:
      return {
        ...state,
        contactsList: action.payload,
      };
    /* falls through */

    case SET_SELECTED_CONTACT:
      return {
        ...state,
        selectedContact: action.payload,
      };
    /* falls through */

    case SET_CONTACTS_ACTION_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */

    case SET_CONTACTS_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default contactsReducer;
