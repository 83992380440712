import React from "react";
import HeaderText from "../components/global/HeaderText";
import TabsComponent from "../components/global/TabsComponent";
import Stores from "../components/admin/Stores";
import Employees from "../components/admin/Employees";

function Administration() {
  const tabs = [
    { name: "Stores", component: <Stores /> },
    { name: "Employees", component: <Employees /> },
  ];
  return (
    <div className="flex-1 h-full">
      <HeaderText title="Administration" />
      <TabsComponent tabsList={tabs} />
    </div>
  );
}

export default Administration;
