import React from "react";
import img from "../../assets/images/no-data-found.png";
import SelectInput from "../global/SelectInput";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "./Pagination";
import SearchInput from "../global/SearchInput";

function ApiDataTable({
  headers,
  data,
  loadDataAction,
  searchAction,
  totalPageCount,
}) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let options = [
    { value: 10, label: "10 records" },
    { value: 25, label: "25 records" },
    { value: 50, label: "50 records" },
    { value: list?.length, label: "All records" },
  ];

  //   handle select field change
  const handleChange = (e) => {
    const val = e.target.value;
    setRowsPerPage(val);
    loadDataAction(val, page);
  };

  //   handle search
  const handleSearch = () => {
    if (query) searchAction(JSON.stringify(query));
  };

  //   handle search
  const handleSearchInput = (e) => {
    let searchText = e.target.value.trim();
    if (searchText) {
      setQuery(searchText);
    } else {
      loadDataAction(rowsPerPage, page);
      setQuery(null);
    }
  };

  useEffect(() => {
    setList(data);
  }, [data]);

  //   Handle page change
  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPageCount &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
      loadDataAction(rowsPerPage, selectedPage);
    }
  };

  const dataTable = list?.map((row, index) => {
    let rowData = [];
    let i = 0;

    for (const key in row) {
      rowData.push({
        key: headers[i],
        val: row[key],
      });
      i++;
    }
    return (
      <tr
        className=" hover:bg-blue-50 sm:border sm:border-gray-200 sm:shadow-lg sm:grid sm:divide-y sm:divide-gray-200 sm:bg-white sm:rounded-xl even:bg-gray-200 "
        key={index}
      >
        {rowData.map((data, index) => (
          <td
            className={`py-2 sm:py-3 px-3 text-[12px] tracking-wide text-gray-600 before:text-gray-700 sm:flex sm:before:justify-between sm:basis sm:before:basis-1/2 sm:before:content-[attr(data-label)]`}
            key={index}
            data-label={data.key}
            data-heading={data.key}
          >
            {data.val}
          </td>
        ))}
      </tr>
    );
  });

  return (
    <div>
      {list?.length > 0 ? (
        <div className=" bg-transparent py-1 sm:bg-transparent border border-gray-200 sm:border-none min-h-fit rounded-lg shadow-sm mb-3">
          <div className={`${list?.length ? "" : "hidden"}`}>
            <div
              className={`flex flex-row justify-between mt-1  space-x-4 border-b sm:border-none border-gray-200 p-2 sm:p-0 sm:my-2`}
            >
              <div className={`w-max sm:w-1/3`}>
                <SelectInput
                  label="Show"
                  onChange={handleChange}
                  options={options}
                  name="rowsPerPage"
                  value={rowsPerPage}
                />
              </div>
              <div className="w-1/3 sm:w-2/3">
                <SearchInput action={handleSearch} search={handleSearchInput} />
              </div>
            </div>
            <div className="flex px-2 sm:px-0 mb-1 ">
              <table className={` w-full`}>
                <thead className="border-b border-gray-300 sm:hidden">
                  <tr className="">
                    {headers.map((col, index) => (
                      <th
                        className="py-2 px-3 text-[12px] font-semibold tracking-wide text-left text-gray-500"
                        key={index}
                      >
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="sm:space-y-2 divide-y divide-gray-200">
                  {dataTable}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className={`items-center w-full h-full flex justify-center`}>
          <img src={img} alt="" />
        </div>
      )}

      {/* PAGINATTION */}
      <div className={`flex flex-row justify-between items-center py-3`}>
        {totalPageCount > 0 && list?.length > 0 ? (
          <div className={`flex justify-between`}>
            <div className={`text-xs text-gray-600`}>
              {`Page ${page} of ${totalPageCount}`}
            </div>
          </div>
        ) : null}
        {totalPageCount > 0 && list?.length > 0 ? (
          <div className="flex justify-between">
            <Pagination
              className="flex justify-between space-x-4 sm:space-x-2"
              currentPage={page}
              totalCount={totalPageCount * rowsPerPage}
              pageSize={rowsPerPage}
              onPageChange={(page) => selectPageHandler(page)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ApiDataTable;
