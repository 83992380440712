export const INITIALIZE_EMPLOYEE_STATE = "INITIALIZE_EMPLOYEE_STATE";

export const TOGGLE_FETCHING_EMPLOYEES = "TOGGLE_FETCHING_EMPLOYEES";

export const SET_EMPLOYEES = "SET_EMPLOYEES";

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";

export const TOGGLE_EMPLOYEE_STATUS = "TOGGLE_EMPLOYEE_STATUS";
