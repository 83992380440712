import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import { standardDateTime } from "../components/global/DateUtilities copy";
import Loader from "../components/global/Loader";
import ApiDataTable from "../components/datatable/ApiDataTable";
import { fetchNotificationsAction } from "../redux/notifications/notificationsActions";
import HeaderText from "../components/global/HeaderText";

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationstate = useSelector((state) => state.notifications);

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(notificationstate?.notifications);
  }, [notificationstate, notificationstate?.notifications]);

  useEffect(() => {
    loadList(10, 1);
    // eslint-disable-next-line
  }, []);

  const loadList = (limit, page) => {
    dispatch(fetchNotificationsAction(limit, page));
  };

  const headers = [
    "Ref",
    "Client",
    "Client Number",
    "Amount Paid",
    "Transaction Date",
  ];
  let tableRows = [];

  if (list?.data?.length) {
    for (let i = 0; i < list?.data?.length; i++) {
      const element = list?.data[i];

      tableRows.push({
        ref: element?.ref ?? "",
        client: element?.payer.full_name ?? "",
        client_number: element?.payer.phone_number ?? "",
        amount: <CurrencyFormatter value={parseInt(element.amount)} />,
        created_at: standardDateTime(element.created_at),
      });
    }
  }

  return (
    <div>
      {/* header information */}
      <HeaderText title="Notifications" />

      {notificationstate?.fetchingNotifications ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={loadList}
          totalPageCount={list?.last_page}
        />
      )}
    </div>
  );
};

export default Notifications;
