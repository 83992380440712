import React, { useState } from "react";
import bgImg from "../assets/images/mainBg.jpg";
import logo from "../assets/images/logo.png";
import AuthInput from "../components/global/AuthInput";
import Button from "../components/global/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { signUpAction } from "../redux/auth/authActions";
import { initializeToken } from "../redux/ApiClient";
import Footer from "../components/footer/Footer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function SignUp() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showPinConfirmation, setShowPinConfirmation] = useState(false);

  initializeToken();

  let goBack = () => {
    window.location.href = "/";
  };

  const userLocation = useSelector((state) => state.auth.userLocation);

  let [signUpData, setSignUpData] = useState({
    email_address: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    latitude: userLocation?.latitude,
    longitude: userLocation?.longitude,
    pin: "",
    pin_confirmation: "",
    account_email: "",
    business_name: "",
    account_phone_number: "",
  });

  let handleSignUpData = (e) => {
    const value = e.target.value;
    setSignUpData({ ...signUpData, [e.target.name]: value });
  };

  let togglePinIcon = () => {
    setShowPin(!showPin);
  };

  let togglePinConfirmationIcon = () => {
    setShowPinConfirmation(!showPinConfirmation);
  };

  //   execute login process
  const submitSignupForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      signUpData.first_name?.trim() !== "" &&
      signUpData.last_name?.trim() !== "" &&
      signUpData.account_email?.trim() !== "" &&
      signUpData.phone_number?.trim() !== "" &&
      signUpData.pin?.trim() !== "" &&
      signUpData.pin_confirmation?.trim() !== "" &&
      signUpData.account_email !== "" &&
      signUpData.account_phone_number !== "" &&
      signUpData.business_name !== "" &&
      signUpData.latitude !== "" &&
      signUpData.longitude !== ""
    ) {
      if (signUpData.pin !== signUpData.pin_confirmation) {
        toast.error("Your PIN and PIN confirmation don't match!.");
        setLoading(false);
      } else {
        dispatch(signUpAction(signUpData)).then((response) => {
          if (response.status) {
            toast.success("Signed Up in successfuly");
            setLoading(false);
            window.location.href = "/home";
          } else {
            toast.error(response.data);
            setLoading(false);
          }
        });
      }
    } else {
      setLoading(false);
      toast.error("All fields are required");
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="flex-1 h-screen overflow-hidden bg-fixed"
    >
      <div className="flex overflow-hidden bg-blue-500 bg-opacity-70 top-[5%] sm:top-[0%] sm:h-full bottom-[5%] left-[5%] sm:left-0 w-[90%] sm:w-full absolute sm:relative  rounded-3xl sm:rounded-none p-8 sm:p-0">
        {/* right side container */}
        <div className="sm:hidden md:hidden w-1/2 items-center flex justify-center">
          <div className="flex flex-col items-center justify-center">
            {/* <h1 className="text-white text-6xl font-audiowide-regular">
              PayGram
            </h1> */}
            <img className="h-44 rounded-full" src={logo} alt="Logo" />
          </div>
        </div>
        {/* end of eft container */}

        {/* right side container */}
        <div className="sm:h-screen overflow-y-scroll py-2 flex-col sm:justify-start justify-center items-center w-1/2 sm:w-full md:w-full sm:border-l-0 md:border-l-0 border-l border-l-secondary">
          {/* logo to be displayed on small devices only */}
          <div className="hidden sm:flex md:flex flex-col items-center justify-center mb-2">
            <img className="w-[30%] rounded-full" src={logo} alt="Logo" />
          </div>

          {/* body of the form */}
          {/* title */}
          <div className="my-4 m-auto flex justify-center">
            <h3 className="text-3xl sm:text-xl text-white font-bold">
              Create account
            </h3>
          </div>

          {/* form */}
          <form onSubmit={submitSignupForm}>
            <div className="px-[15%] sm:px-4 w-[95%] sm:w-full m-auto">
              {/* input fields */}
              <AuthInput
                prefix={
                  <i className="bx bxs-user-circle text-2xl text-gray-300"></i>
                }
                name="first_name"
                type="text"
                value={signUpData.first_name}
                onChange={handleSignUpData}
                required={true}
                placeholder="First name"
              />
              <AuthInput
                prefix={
                  <i className="bx bxs-user-circle text-2xl text-gray-300"></i>
                }
                name="last_name"
                type="text"
                value={signUpData.last_name}
                onChange={handleSignUpData}
                required={true}
                placeholder="Last name"
              />
              <AuthInput
                prefix={
                  <i className="bx bxs-envelope text-2xl text-gray-300"></i>
                }
                name="email_address"
                type="text"
                value={signUpData.email_address}
                onChange={handleSignUpData}
                required={true}
                placeholder="Email address"
              />
              <AuthInput
                prefix={<i className="bx bxs-phone text-2xl text-gray-300"></i>}
                name="phone_number"
                type="text"
                value={signUpData.phone_number}
                onChange={handleSignUpData}
                required={true}
                placeholder="Phone number"
              />
              <AuthInput
                prefix={
                  <i className="bx bxs-user-circle text-2xl text-gray-300"></i>
                }
                name="business_name"
                type="text"
                value={signUpData.business_name}
                onChange={handleSignUpData}
                required={true}
                placeholder="Business name"
              />
              <AuthInput
                prefix={
                  <i className="bx bxs-envelope text-2xl text-gray-300"></i>
                }
                name="account_email"
                type="text"
                value={signUpData.account_email}
                onChange={handleSignUpData}
                required={true}
                placeholder="Business email address"
              />
              <AuthInput
                prefix={<i className="bx bxs-phone text-2xl text-gray-300"></i>}
                name="account_phone_number"
                type="text"
                value={signUpData.account_phone_number}
                onChange={handleSignUpData}
                required={true}
                placeholder="Business phone number"
              />
              <AuthInput
                prefix={<i className="bx bxs-lock text-2xl text-gray-300"></i>}
                name="pin"
                type={showPin ? "text" : "password"}
                value={signUpData.pin}
                onChange={handleSignUpData}
                required={true}
                placeholder="PIN"
                maxLength={4}
                suffix={
                  <span className="cursor-pointer" onClick={togglePinIcon}>
                    {showPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </span>
                }
              />
              <AuthInput
                prefix={<i className="bx bxs-lock text-2xl text-gray-300"></i>}
                name="pin_confirmation"
                type={showPinConfirmation ? "text" : "password"}
                value={signUpData.pin_confirmation}
                onChange={handleSignUpData}
                required={true}
                placeholder="PIN Confirmation"
                maxLength={4}
                suffix={
                  <span
                    className="cursor-pointer"
                    onClick={togglePinConfirmationIcon}
                  >
                    {showPinConfirmation ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </span>
                }
              />
            </div>

            {/* action button */}
            <div className="px-[17%] sm:px-4 flex w-full">
              {
                <Button
                  bgColor={"bg-secondary"}
                  type="submit"
                  text="Sign up"
                  disabled={loading}
                />
              }
            </div>
          </form>

          <div className="flex mt-3 m-auto justify-center">
            <p
              onClick={goBack}
              className="text-white cursor-pointer sm:text-sm"
            >
              Back to login page
            </p>
          </div>
          <div className="bottom-4 mt-[0%] w-full">
            <Footer />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default SignUp;
