/* eslint-disable import/no-anonymous-default-export */
import clientsApi from "../redux/ClientsApi";

export default {
  fetchNotifications(limit, page) {
    return clientsApi.get(
      `/client/notifications/list?rows=${limit}&page=${page}`
    );
  },
  markAsRead(data) {
    return clientsApi.patch("/client/notifications/read", data);
  },
  markAllAsRead() {
    return clientsApi.patch("/client/notifications/read/all");
  },
};
