import React from "react";

function CheckBox(props) {
  return (
    <div className="flex items-center mb-2">
      <input
        onChange={props.onChange}
        checked={props.isChecked}
        className="mr-2 h-4 w-4 cursor-pointer"
        type="checkbox"
        name={props.name}
        value={props.value}
      />

      <label
        className={`${
          props.textColor ? props.textColor : "text-gray-700"
        } text-sm`}
      >
        {props.title}
      </label>
    </div>
  );
}

export default CheckBox;
