/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  fetchStores() {
    return apiClient.get("/store/view");
  },
  createStore(data) {
    return apiClient.post("/store/create", data);
  },
  updateStore(data) {
    return apiClient.put("/store/update", data);
  },
};
