import React from "react";

const FabButton = ({ onPressAction, text, icon }) => {
  return (
    <button
      onClick={onPressAction}
      className={`bg-primary opacity-50 flex items-center justify-center right-6 sm:right-3 py-2 px-3 rounded-full absolute bottom-16 z-5 shadow-2xl`}
    >
      <div className="text-white flex-row flex items-center space-x-2">
        <i
          className={`${
            icon ? icon : "bx bx-plus"
          } text-white text-2xl text-center`}
        ></i>
        <p className={`${text ? "" : "hidden"}`}>{text ? text : ""}</p>
      </div>
    </button>
  );
};

export default FabButton;
