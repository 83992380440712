/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  fetchEmployees() {
    return apiClient.get("/store/employee/view");
  },
  createEmployee(data) {
    return apiClient.post("/store/employee/create", data);
  },
};
