import {
  INITIALIZE_TRANSACTIONS_STATE,
  SET_MONEY_TRANSFERS,
  SET_MONEY_TRANSFER_RECORD,
  SET_TRANSACTIONS,
  SET_TRANSACTION_ERROR_MESSAGE,
  SET_TRANSACTION_SUCCESS_MESSAGE,
  TOGGLE_FETCHING_MONEY_TRANSFERS,
  TOGGLE_FETCHING_TRANSACTIONS,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingTransactions: false,
    transactions: [],
    fetchingMoneyTransfers: false,
    moneyTransfers: {},
  };
};

// set the reducer
const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_TRANSACTIONS_STATE:
      return {
        ...state,
        success: "",
        error: "",
      };
    /* falls through */
    case TOGGLE_FETCHING_TRANSACTIONS:
      return {
        ...state,
        fetchingTransactions: action.payload,
      };
    case TOGGLE_FETCHING_MONEY_TRANSFERS:
      return {
        ...state,
        fetchingMoneyTransfers: action.payload,
      };
    /* falls through */
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    /* falls through */
    case SET_MONEY_TRANSFERS:
      return {
        ...state,
        moneyTransfers: action.payload,
      };
    /* falls through */

    case SET_MONEY_TRANSFER_RECORD:
      return {
        ...state,
        moneyTransfers: {
          ...state.moneyTransfers,
          data: [...state.moneyTransfers?.data, action.payload],
        },
      };
    /* falls through */

    case SET_TRANSACTION_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_TRANSACTION_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default transactionsReducer;
