import React, { useEffect, useState } from "react";
import Loader from "../components/global/Loader";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import HeaderText from "../components/global/HeaderText";
import {
  createPaymentAction,
  exportTransactionsReport,
  fetchAContactAction,
  fetchTransactionsAction,
} from "../redux/Index";
import Button from "../components/global/Button";
import TextInput from "../components/global/TextInput";
import FabButton from "../components/global/FabButton";
import { ToastContainer, toast } from "react-toastify";
import ApiDataTable from "../components/datatable/ApiDataTable";
import { standardDateTime } from "../components/global/DateUtilities";
import GlobalModal from "../components/global/GlobalModal";

function PaymentsTransactions() {
  const dispatch = useDispatch();
  const transactionsState = useSelector((state) => state.transactions);

  const userLocation = useSelector((state) => state.auth?.userLocation);
  const [list, setList] = useState(null);
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [contact, setContact] = useState(null);
  const [fetchingContact, setFetchingContact] = useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleGlobalModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    setList(transactionsState?.transactions);
  }, [transactionsState?.transactions]);

  useEffect(() => {
    loadList(10, 1);
    // eslint-disable-next-line
  }, []);

  const loadList = (limit, page) => {
    dispatch(fetchTransactionsAction(limit, page));
  };

  // FORM OPERATIONS

  const [inputValues, setInputValues] = useState({
    payer: "",
    amount: "",
  });

  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    inputValues.payment_latitude = userLocation?.latitude?.toFixed(15);
    inputValues.payment_longitude = userLocation?.longitude?.toFixed(15);
    inputValues.payer = contact?.id;

    if (inputValues.amount?.trim() !== "") {
      dispatch(createPaymentAction(inputValues)).then((response) => {
        if (response.status) {
          setIsSubmit(false);
          toggleGlobalModal();
          toast.success(response.data);
        } else {
          setIsSubmit(false);
          toast.error("Failed to execute the request");
        }
      });
    } else {
      setIsSubmit(false);
      toast.error("Fill in the form correctly");
    }
  };

  const fabIconClick = () => {
    toggleGlobalModal();
    setShowStepTwo(false);
    setInputValues({
      payer: "",
      amount: "",
    });
    setIsSubmit(false);
  };

  const fetchContact = () => {
    setFetchingContact(true);
    if (inputValues.payer?.trim() !== "") {
      dispatch(fetchAContactAction(inputValues.payer)).then((response) => {
        if (response.status) {
          setFetchingContact(false);
          setContact(response.message);
          setShowStepTwo(true);
        } else {
          setFetchingContact(false);
          toast.error(response.message);
        }
      });
    } else {
      setFetchingContact(false);
      toast.error("Fill in the form correctly");
    }
  };

  // DISPLAY TABLE

  const headers = [
    "Transaction ID",
    "Client",
    "Client Number",
    "Amount paid",
    "Transaction date",
  ];
  let tableRows = [];

  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        transactionID: element?.ref ?? "",
        client: element?.store?.client.full_name ?? "",
        clientId: element?.store?.client.phone_number ?? "",
        amount: (
          <CurrencyFormatter
            value={parseInt(element.cr_amount || element.dr_amount)}
          />
        ),
        created_at: standardDateTime(element.created_at),
      });
    }
  }

  const exportReport = () => {
    setIsSubmit(true);
    dispatch(exportTransactionsReport("pdf")).then((status) => {
      if (status) {
        setIsSubmit(false);
        toast.success("Your report has been emailed to you.");
      } else {
        setIsSubmit(false);
        toast.error("Failed to process your request.");
      }
    });
  };

  return (
    <div className="flex-1 h-full">
      <FabButton
        onPressAction={fabIconClick}
        text="Request payment"
        icon="bx bx-wallet-alt"
      />
      {/* header information */}
      <div className="flex flex-row items-center justify-between">
        <HeaderText title="Received Payments" />
        {/* display the total for received payments */}
        <div className="flex flex-row items-center justify-between space-x-4">
          <p className="sm:text-xs text-center">
            Total amount received: Ksh. 900
          </p>
          <button
            type="button"
            onClick={exportReport}
            className="text-white sm:text-xs font-bold text-sm bg-primary px-4 h-8 rounded-md flex flex-row items-center"
          >
            <i class="bx bxs-file-pdf text-lg"></i>
            <p className="sm:hidden">Export PDF</p>
          </button>
        </div>
      </div>
      {transactionsState?.fetchingTransactions ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={loadList}
          totalPageCount={list?.last_page}
        />
      )}

      <GlobalModal modalOpen={modalOpen} handleClose={toggleGlobalModal}>
        <p className="text-gray-600 mb-3">Request Payment</p>
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          {!showStepTwo ? (
            <div>
              <TextInput
                required="required"
                label="Payer e.g +254 712 345 678"
                name="payer"
                type="numeric"
                value={inputValues.payer}
                onChange={handleChange}
              />

              <Button
                type={"button"}
                text={fetchingContact ? "Fetching data..." : "Continue"}
                disabled={fetchingContact}
                onClick={fetchContact}
              />
            </div>
          ) : (
            <div>
              <div className="flex flex-col justify-center items-center pt-4  mb-4 w-full">
                {contact?.image_url ? (
                  <img
                    className="rounded-full h-24 w-24 bg-gray-100 shadow-md"
                    src={contact?.image_url}
                    alt=""
                  />
                ) : null}
                <div className="flex flex-col w-full items-center justify-center">
                  <p className={`text-lg text-gray-500 font-semibold`}>
                    {contact?.name ? contact?.name : "Unknown user"}
                  </p>
                  <p className="text-sm text-gray-400">
                    {contact?.phone_number
                      ? contact?.phone_number
                      : "Unknown number"}
                  </p>
                </div>
              </div>

              <TextInput
                required="required"
                label="Amount"
                name="amount"
                type="numeric"
                value={inputValues.amount}
                onChange={handleChange}
              />
              <Button
                text={isSubmit ? "Processing..." : "Request"}
                disabled={isSubmit}
              />
            </div>
          )}
        </form>
      </GlobalModal>

      <ToastContainer />
    </div>
  );
}

export default PaymentsTransactions;
