import axios from "axios";
import { CLIENT_BASE_URL } from "./api";

const clientsApi = axios.create({
  baseURL: CLIENT_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "*/*",
    "Content-Type": "*/*",
  },
});

function initializeClientToken() {
  setAccessToken();
}

async function setAccessToken() {
  let token = "";
  clientsApi.defaults.headers.common["Authorization"] = "";
  delete clientsApi.defaults.headers.common["Authorization"];

  if (localStorage.getItem("persist:pgMerchant") !== null) {
    if ("auth" in JSON.parse(localStorage.getItem("persist:pgMerchant"))) {
      let data = JSON.parse(localStorage.getItem("persist:pgMerchant"));
      token = JSON.parse(data?.auth).token;
    } else {
      token = null;
    }
  } else {
    token = null;
  }

  if (token) {
    clientsApi.defaults.headers.common["Authorization"] = `Token ${token}`;
  }
}

initializeClientToken();

clientsApi.interceptors.response.use((response) => {
  // console.log(response);
  return response;
});

export { initializeClientToken };
export default clientsApi;
