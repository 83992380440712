import {
  CLEAR_PAYMENTS_STATE,
  CREATE_PAYMENT,
  SET_PAYMENTS,
  SET_PAYMENT_REQUEST,
  SET_SELECTED_PAYMENT,
  TOGGLE_FETCHING_PAYMENTS,
  TOGGLE_FETCH_PAYMENT_REQUEST,
} from "./paymentsActionTypes";

// set the initial state
const initialState = () => {
  return {
    payments: {},
    payment_requests: {},
    fetchingPaymentRequest: false,
    fetchingPayments: false,
    selectedPayment: {},
  };
};

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_PAYMENTS_STATE:
      return { ...state, selectedPayment: {} };

    case TOGGLE_FETCHING_PAYMENTS:
      return { ...state, fetchingPayments: action.payload };

    case TOGGLE_FETCH_PAYMENT_REQUEST:
      return { ...state, fetchingPaymentRequest: action.payload };

    case SET_PAYMENT_REQUEST:
      return { ...state, payment_requests: action.payload };

    case SET_PAYMENTS:
      return { ...state, payments: action.payload };

    case CREATE_PAYMENT:
      return {
        ...state,
        payments: [...state.payments?.data, action.payload],
      };

    case SET_SELECTED_PAYMENT:
      return { ...state, selectedPayment: action.payload };

    default:
      return state;
  }
};

export default paymentsReducer;
