import React from "react";

const SelectInput = (props) => {
  const sortedList = props.options?.sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
  const setVal = (e) => {
    const { value } = e.target;
    props.onChange(props.name, value);
  };
  return (
    <div className="relative w-full mb-6 border-b h-10 border-gray-300 px-0">
      <select
        name={props.name}
        className="relative bg-transparent sm:w-full sm:mb-2 h-9 px-1 py-2 text-sm text-gray-500  focus:outline-none after:text-2xl w-full"
        required={props.required ? "required" : ""}
        value={props.value}
        onChange={setVal}
      >
        <option key={0} value={null}>
          Select
        </option>
        {sortedList.map((option) => (
          <option key={option.value + option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className="select-highlight"></span>
      <span className="select-bar"></span>
      <label
        className={`${
          props.value ? "absolute" : "hidden"
        } text-gray-500 text-[11px] cursor-none left-1 -top-3 sm:bg-gray-50 bg-transparent transition-all ease-in-out duration-700 px-1`}
      >
        {props.label}
      </label>
    </div>
  );
};

export default SelectInput;
