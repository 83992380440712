import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchingDocumentsAction } from "../redux/Index";
import Loader from "../components/global/Loader";

function Authorization() {
  const dispatch = useDispatch();
  const documentsState = useSelector((state) => state.documents);

  const [data, setData] = useState(documentsState.documents);

  useEffect(() => {
    dispatch(fetchingDocumentsAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(documentsState?.documents);
  }, [documentsState?.documents]);

  if (!documentsState.fetchingDocuments) {
    if (!data?.length) {
      window.location.href = "/documents";
    } else {
      window.location.href = "/home";
    }
  }

  return (
    <div>
      <Loader />
    </div>
  );
}

export default Authorization;
