import {
  INITIALIZE_NOTIFICATION_STATE,
  SET_NOTIFICATIONS,
  TOGGLE_FETCHING_NOTIFICATIONS,
  MARK_AS_READ,
  MARK_ALL_AS_READ,
} from "./notificationsActionTypes";

// set the initial state
const initialState = () => {
  return {
    fetchingNotifications: false,
    notifications: [],
  };
};

// set the reducer
const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_NOTIFICATION_STATE:
      return {
        ...state,
      };
    /* falls through */

    case TOGGLE_FETCHING_NOTIFICATIONS:
      return {
        ...state,
        fetchingNotifications: action.payload,
      };
    /* falls through */

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    /* falls through */

    case MARK_AS_READ:
      let newnotifications = [...state.notifications];
      let foundIndex = newnotifications?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newnotifications[foundIndex] = action.payload;
      return {
        ...state,
        notifications: newnotifications,
      };
    /* falls through */

    case MARK_ALL_AS_READ:
      let allNotifications = [...state.notifications];
      let noteIndex = allNotifications?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      allNotifications[noteIndex] = action.payload;
      return {
        ...state,
        notifications: allNotifications,
      };
    /* falls through */

    default:
      return state;
  }
};

export default notificationsReducer;
