/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { useDispatch, useSelector } from "react-redux";
import { closeAddSideModal, openAddSideModal } from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";
import { Switch } from "@mui/material";
import CustomDateFormat from "../global/CustomDateFormat";
import {
  createEmployeeAction,
  fetchEmployeesAction,
  updateEmployeeAction,
} from "../../redux/employees/employeesActions";
import SelectInput from "../global/SelectInput";
import { countryCodes, genderOptions } from "../../constants/data";

function Employees() {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [employees, setEmployees] = useState(null);
  const dispatch = useDispatch();
  const employeesState = useSelector((state) => state.employees);

  useEffect(() => {
    loadEmployees();
  }, []);

  const loadEmployees = () => {
    dispatch(fetchEmployeesAction());
  };

  useEffect(() => {
    setEmployees(employeesState.employees);
  }, [employeesState.employees]);

  const headers = [
    "Name",
    "Email address",
    "Telephone",
    "Created on",
    "Status",
    "Edit",
  ];
  let tableRows = [];
  if (employees?.length) {
    for (let i = 0; i < employees?.length; i++) {
      const element = employees[i];
      tableRows.push({
        name: `${element.full_name}`,
        account_email: element.email_address,
        account_phone_number: element.phone_number,
        created_at: <CustomDateFormat>{element.created_at}</CustomDateFormat>,
        status: (
          <Switch
            checked={element.status}
            onChange={() => toggleEmployeeStatus(element.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        edit: (
          <i
            className="bx bx-edit links text-[20px] cursor-pointer"
            onClick={() => editDetails(element)}
          ></i>
        ),
      });
    }
  }

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        first_name: selectedItem?.first_name,
        last_name: selectedItem?.last_name,
        email_address: selectedItem?.email_address,
        phone_number: selectedItem?.phone_number,
        gender: selectedItem?.gender,
        id_number: selectedItem?.id_number,
        country_code: selectedItem?.country_code,
        date_of_birth: selectedItem?.date_of_birth,
      });
    }
  }, [isEdit, selectedItem]);

  const toggleEmployeeStatus = (id) => {
    console.log(id);
  };
  const editDetails = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    gender: "",
    id_number: "",
    country_code: "",
    date_of_birth: "",
  });

  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //   handle field change
  const handleSelectChange = (name, val) => {
    setInputValues({ ...inputValues, [name]: val });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      inputValues.first_name?.trim() !== "" ||
      inputValues.last_name?.trim() !== "" ||
      inputValues.email_address?.trim() !== "" ||
      inputValues.phone_number?.trim() !== "" ||
      inputValues.gender?.trim() !== "" ||
      inputValues.id_number?.trim() !== "" ||
      inputValues.country_code?.trim() !== "" ||
      inputValues.date_of_birth?.trim() !== ""
    ) {
      if (isEdit) {
        inputValues.id = selectedItem.id;
      }
      dispatch(
        isEdit
          ? updateEmployeeAction(inputValues)
          : createEmployeeAction(inputValues)
      ).then((status) => {
        if (status) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Operation was successful");
        } else {
          setIsSubmit(false);
          toast.error("Failed to execute the request");
        }
      });
    }
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setInputValues({
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      gender: "",
      id_number: "",
      country_code: "",
      date_of_birth: "",
    });
  };

  if (employeesState.fetchingStores) {
    return <Loader />;
  }

  return (
    <div className="flex-1 h-full">
      <FabButton text={"Add Employee"} onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel
        width="30%"
        title="Employees"
        subtitle={isEdit ? "Update an Employee" : "Create an Employee"}
      >
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="First name"
            name="first_name"
            type="text"
            value={inputValues.first_name}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Last name"
            name="last_name"
            type="text"
            value={inputValues.last_name}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Email address"
            name="email_address"
            type="email"
            value={inputValues.email_address}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Telephone number"
            name="phone_number"
            type="numeric"
            value={inputValues.phone_number}
            onChange={handleChange}
          />
          <SelectInput
            label="Gender"
            onChange={handleSelectChange}
            options={genderOptions}
            name="gender"
            value={inputValues?.gender}
          />
          <TextInput
            required="required"
            label="ID number"
            name="id_number"
            type="numeric"
            value={inputValues.id_number}
            onChange={handleChange}
          />
          <SelectInput
            label="Country code"
            onChange={handleSelectChange}
            options={countryCodes}
            name="country_code"
            value={inputValues?.country_code}
          />
          <TextInput
            required="required"
            label="Date of birth"
            name="date_of_birth"
            type="date"
            value={inputValues.date_of_birth}
            onChange={handleChange}
          />
          <Button
            text={isSubmit ? "Processing..." : isEdit ? "Update" : "Create"}
            disabled={isSubmit}
          />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

export default Employees;
