import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentRequestsAction } from "../../redux/Index";
import {
  isDateTimeInThePast,
  standardDateTime,
} from "../../components/global/DateUtilities copy";
import CurrencyFormatter from "../../components/global/CurrencyFormatter";
import Loader from "../../components/global/Loader";
import ApiDataTable from "../../components/datatable/ApiDataTable";

const PaymentsRequest = () => {
  const dispatch = useDispatch();
  const paymentState = useSelector((state) => state.payments);

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(paymentState?.payment_requests);
  }, [paymentState, paymentState?.payment_requests]);

  useEffect(() => {
    loadList(10, 1);
    // eslint-disable-next-line
  }, []);

  const loadList = (limit, page) => {
    dispatch(fetchPaymentRequestsAction(limit, page));
  };

  const headers = [
    "Client",
    "Client Number",
    "Amount",
    "Status",
    "Initiated At",
    "Updated At",
  ];
  let tableRows = [];

  if (list?.data?.length) {
    for (let i = 0; i < list?.data?.length; i++) {
      const element = list?.data[i];

      const updatedAt = element?.approved_at
        ? standardDateTime(element.approved_at)
        : element?.cancelled_at
        ? standardDateTime(element.cancelled_at)
        : element.rejected_at
        ? standardDateTime(element.rejected_at)
        : "";

      tableRows.push({
        client: element?.payer.full_name ?? "",
        client_number: element?.payer.phone_number ?? "",
        amount: <CurrencyFormatter value={parseInt(element.amount)} />,
        // ! Improve the status
        status: element?.approved_at
          ? "Approved"
          : element?.cancelled_at
          ? "Cancelled"
          : element.rejected_at
          ? "Rejected"
          : isDateTimeInThePast(element.expires_at, element.created_at)
          ? "Expired"
          : "Pending",
        initiated_at: standardDateTime(element.created_at),
        updated_at: updatedAt,
      });
    }
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-end my-2">
        <p className="text-gray-700 text-sm">Total amount requested:&nbsp; </p>
        <CurrencyFormatter value={list?.total_transfers ?? 0} />
      </div>
      {paymentState?.fetchingPaymentRequest ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={loadList}
          totalPageCount={list?.last_page}
        />
      )}
    </div>
  );
};

export default PaymentsRequest;
