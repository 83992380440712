import StoresClient from "../../api/StoresClient";
import {
  CREATE_STORE,
  INITIALIZE_STORE_STATE,
  SET_STORES,
  TOGGLE_FETCHING_STORES,
  TOGGLE_STORE_STATUS,
  UPDATE_STORE,
} from "./storeActionTypes";
// set the actions
export const initializeStoresState = () => {
  return {
    type: INITIALIZE_STORE_STATE,
  };
};

export const toggleFetchingStores = (status) => {
  return {
    type: TOGGLE_FETCHING_STORES,
    payload: status,
  };
};

export const setStores = (data) => {
  return {
    type: SET_STORES,
    payload: data,
  };
};

export const createStore = (data) => {
  return {
    type: CREATE_STORE,
    payload: data,
  };
};

export const updateStore = (data) => {
  return {
    type: UPDATE_STORE,
    payload: data,
  };
};

export const toggleStoreStatus = (data) => {
  return {
    type: TOGGLE_STORE_STATUS,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchStoresAction() {
  return function action(dispatch) {
    dispatch(initializeStoresState);
    dispatch(toggleFetchingStores(true));

    return StoresClient.fetchStores()
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setStores(response.data.data));
          dispatch(toggleFetchingStores(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingStores(false));
      });
  };
}

export function createStoreAction(data) {
  return function action(dispatch) {
    return StoresClient.createStore(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(createStore(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}

export function updateStoreAction(data) {
  return function action(dispatch) {
    return StoresClient.updateStore(data)
      .then((response) => {
        console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateStore(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  };
}

export function toggleStoreStatusAction(data) {
  return function action(dispatch) {
    return StoresClient.createStore(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(toggleStoreStatus(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}
