import React, { useEffect, useState } from "react";
import DashboardCard from "../components/dashboard/DashboardCard";
import MonthlyRevenue from "../components/dashboard/MonthlyRevenue";
import HeaderText from "../components/global/HeaderText";
import NumberOfTransactionsChart from "../components/dashboard/NumberOfTransactionsChart";
import { useDispatch, useSelector } from "react-redux";
import { fetchingDashboardAnalysisAction } from "../redux/dashboard/dashboardActions";
import Loader from "../components/global/Loader";
import CurrencyFormatter from "../components/global/CurrencyFormatter";

function Dashboard() {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const [data, setData] = useState(dashboardState.dashboardAnalysis);

  useEffect(() => {
    dispatch(fetchingDashboardAnalysisAction(3));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(dashboardState.dashboardAnalysis);
  }, [dashboardState.dashboardAnalysis]);

  const cards = [
    {
      title: "Number of Transactions today",
      num: data?.today_payments_count,
      icon: <i className="bx bx-list"></i>,
      bgColor: "bg-blue-30",
      showCurrency: false,
    },
    {
      title: "Amount transacted today",
      num: data?.today_payment_amount,
      icon: <i className="bx bx-file"></i>,
      bgColor: "bg-red-50",
      showCurrency: true,
    },
    {
      title: "Amount transacted this week",
      num: data?.week_amount,
      icon: <i className="bx bx-moon"></i>,
      bgColor: "bg-green-50",
      showCurrency: true,
    },
    {
      title: "Amount transacted this month",
      num: data?.month_amount,
      icon: <i className="bx bx-money"></i>,
      bgColor: "bg-gray-50",
      showCurrency: true,
    },
    {
      title: "Commissions to PayGram today",
      num: data?.commissions,
      icon: <i className="bx bx-money"></i>,
      bgColor: "bg-secondary-50",
      showCurrency: true,
    },
  ];

  return (
    <div>
      <HeaderText title="Dashboard" />
      {/* cards */}
      {dashboardState.fetchingDashboardAnalysis ? (
        <Loader />
      ) : (
        <div className="flex space-x-2 w-full sm:grid sm:space-x-0 sm:space-y-3 md:space-x-2">
          {cards.map((item, index) => (
            <DashboardCard
              key={index}
              title={item.title}
              num={
                item.showCurrency ? (
                  <CurrencyFormatter value={item.num} />
                ) : (
                  item.num
                )
              }
              icon={item.icon}
              bgColor={item.bgColor}
            />
          ))}
        </div>
      )}

      {/*Total revenue chart */}
      <div className="w-full border-gray-200 border rounded-md shadow-sm mt-4">
        <div className=" text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2">
          Total volume per month
        </div>
        <div className="px-4 py-2">
          <MonthlyRevenue />
        </div>
      </div>
      {/*monthly Total number of Transactions chart */}
      <div className="w-full border-gray-200 border rounded-md shadow-sm my-4">
        <div className=" text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2">
          Number of Transactions
        </div>
        <div className="px-4 py-2">
          <NumberOfTransactionsChart />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
