import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "../components/global/Button";
import logo from "../assets/images/avatar.jpg";
import TextInput from "../components/global/TextInput";
import HeaderText from "../components/global/HeaderText";
import GlobalHorizontalDisplay from "../components/global/GlobalHorizontalDisplay";
import { standardDateWithDay } from "../components/global/DateUtilities copy";
import { regenerateQrCode } from "../redux/auth/authActions";

function Profile(props) {
  const dispatch = useDispatch();
  const inputs = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const userData = props.authStore?.userInfo;

  const [inputValues, setInputValues] = useState(inputs);
  const [isSubmit, setIsSubmit] = useState(false);
  const [imageError, setImageError] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  useEffect(() => {
    if (isSubmit && props.authStore.updatedPass && props.authStore.success) {
      setIsSubmit(false);
      toast.success(props.authStore.success);
      // dispatch(logoutRequestAction());
    } else if (
      isSubmit &&
      !props.authStore.updatedPass &&
      props.authStore.error
    ) {
      toast.error(props.authStore.error);
      setIsSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authStore]);

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      inputValues.current_password !== "" ||
      inputValues.new_password !== "" ||
      inputValues.confirm_password !== ""
    ) {
      if (inputValues.new_password.length < 8) {
        toast.error("Password must be 8 characters long");
        setIsSubmit(false);
      } else {
        if (inputValues.new_password !== inputValues.confirm_password) {
          toast.error("New password does not match confirm password");
          setIsSubmit(false);
        } else {
          // dispatch(updatePasswordAction(inputValues));
          //          console.log("all set");
        }
      }
    } else {
      toast.error("All fields are required");
      setIsSubmit(false);
    }
  };

  const handleRegenerateQrCode = () => {
    // if (!imageError) return;

    dispatch(regenerateQrCode());
  };

  return (
    <div className="">
      {/* page title */}
      <HeaderText title="Account details" />

      {/* profile */}
      <div className="rounded-lg bg-white shadow-md">
        {/* profile pic */}
        <div className="flex flex-col bg-gray-50 justify-center items-center w-full h-40 my-4 p-4">
          <img
            className="rounded-full h-24 w-24 bg-gray-100 shadow-md"
            src={logo}
            alt=""
          />
          <p className="text-lg text-gray-500 font-semibold my-3">
            {userData?.business_name
              ? userData?.business_name
              : userData?.full_name}
          </p>
        </div>

        {/* profile section */}
        <div className="p-2 md:flex sm:flex-col flex justify-between sm:divide-x-0 divide-x divide-gray-200 sm:space-y-4">
          {/* PROFILE DATA */}
          <div className="sm:w-full md:w-full sm:mb-2 md:mb-2 w-1/2 space-y-4">
            <GlobalHorizontalDisplay
              title={userData?.business_name ? "Business name" : "Full name"}
              text={
                userData?.business_name
                  ? userData?.business_name
                  : userData?.full_name
              }
            />
            <GlobalHorizontalDisplay
              title="Email address"
              text={userData?.email}
            />
            <GlobalHorizontalDisplay
              title="Creation date"
              text={standardDateWithDay(userData?.created_at)}
            />
            <GlobalHorizontalDisplay
              title="Phone number"
              text={userData?.phone_number}
            />
            <GlobalHorizontalDisplay
              title="Till number"
              text={userData?.till_number}
            />
            <GlobalHorizontalDisplay
              title="QR Code"
              text={
                <button
                  onClick={handleRegenerateQrCode}
                  className="h-32 w-32 relative cursor-pointer"
                >
                  <img
                    className="h-full w-full bg-gray-100 shadow-sm"
                    src={userData?.qr_code?.file_url}
                    onError={(e) => setImageError(true)}
                    alt="QR code"
                  />
                  {imageError ? (
                    <i className="absolute top-1/2 w-full text-2xl font-extrabold text-center bx bx-refresh"></i>
                  ) : (
                    <i></i>
                  )}
                </button>
              }
            />
          </div>

          {/* UPDATE PASSWORD */}
          <div className="w-1/2 sm:w-full md:w-full pl-4 sm:pl-0">
            <p className="mb-4">Change Password</p>
            <form className="form" onSubmit={handleSubmit} autoComplete="off">
              <TextInput
                required="required"
                label="Current Password"
                name="current_password"
                type="password"
                value={inputValues.current_password}
                onChange={handleChange}
              />
              <TextInput
                required="required"
                label="New Password"
                name="new_password"
                type="password"
                value={inputValues.new_password}
                onChange={handleChange}
              />
              <TextInput
                required="required"
                label="Confirm New Password"
                name="confirm_password"
                type="password"
                value={inputValues.confirm_password}
                onChange={handleChange}
              />
              <Button
                class="btn btn-success bx-pull-right"
                type="submit"
                text={isSubmit ? "Updating..." : "Update"}
                disabled={isSubmit ? "disabled" : ""}
              />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authStore: state.auth ? state.auth : [],
  };
};

export default connect(mapStateToProps)(Profile);
