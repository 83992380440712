import moment from "moment";

export const checkDateIsToday = (date) => {
  const todayDate = moment().format("YYYY-MM-DD");
  const dateString = moment(date).format("YYYY-MM-DD");
  return dateString === todayDate;
};

export const futureDate = (currentDate, months) => {
  const dateString = moment(currentDate).add(months, "M").format("YYYY-MM-DD");
  return dateString;
};

export const getDifferenceInMonths = (start, end) => {
  const difference = moment(start).diff(moment(end), "months");
  return difference > 0 ? difference : difference + 1;
};

export const standardDateTime = (dateValue) => {
  const dateString = moment(dateValue).format("llll");
  return dateString;
};

export const isDateInThisWeek = (date) => {
  const start = moment().startOf("week").format("YYYY-MM-DD");
  const end = moment().endOf("week").format("YYYY-MM-DD");
  const dateString = moment(date).format("YYYY-MM-DD");
  return dateString >= start && dateString <= end; //true if dates are in the same week
};

export const isDateInThisMonth = (date) => {
  const start = moment().startOf("month").format("YYYY-MM-DD");
  const end = moment().endOf("month").format("YYYY-MM-DD");
  const dateString = moment(date).format("YYYY-MM-DD");
  return moment(dateString).isBetween(start, end); //true if dates are in the same month
};
