import React from "react";
import PaymentsRequest from "./PaymentsRequest";
import Payments from "./Payments";
import HeaderText from "../../components/global/HeaderText";
import TabsComponent from "../../components/global/TabsComponent";

const PaymentsTab = () => {
  const tabs = [
    { name: "Requests", component: <PaymentsRequest /> },
    { name: "Payments", component: <Payments /> },
  ];
  return (
    <div className="flex-1 h-full">
      <HeaderText title="Payments" />
      <TabsComponent tabsList={tabs} />
    </div>
  );
};

export default PaymentsTab;
