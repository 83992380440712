import ContactsClient from "../../api/ContactsClient";
import { initializeClientToken } from "../ClientsApi";
import {
  CLEAR_CONTACTS_STATE,
  SET_CONTACTS,
  SET_CONTACTS_ACTION_ERROR,
  SET_CONTACTS_ACTION_SUCCESS,
  SET_SELECTED_CONTACT,
  TOGGLE_FETCHING_A_CONTACT,
  TOGGLE_FETCHING_CONTACTS,
} from "./contactsActionTypes";

export const clearContactsState = () => {
  return { type: CLEAR_CONTACTS_STATE };
};

export const toggleFetchingContacts = (status) => {
  return { type: TOGGLE_FETCHING_CONTACTS, payload: status };
};

export const toggleFetchingAContact = (status) => {
  return { type: TOGGLE_FETCHING_A_CONTACT, payload: status };
};

export const setContacts = (data) => {
  return { type: SET_CONTACTS, payload: data };
};

export const setSelectedContact = (data) => {
  return { type: SET_SELECTED_CONTACT, payload: data };
};

export const setContactsActionSuccess = (msg) => {
  return { type: SET_CONTACTS_ACTION_SUCCESS, payload: msg };
};

export const setContactsActionError = (error) => {
  return { type: SET_CONTACTS_ACTION_ERROR, payload: error };
};

//////////////////// DISPATCH ACTIONS /////////////////////////

// fetch Contacts
export function setSelectedContactAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedContact(data));
  };
}

// fetch Contacts
export function fetchContactsAction() {
  return function action(dispatch) {
    dispatch(clearContactsState);
    dispatch(toggleFetchingContacts(true));
    return ContactsClient.fetchContacts()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setContacts(response.data.data));
          dispatch(toggleFetchingContacts(false));
        } else {
          dispatch(setContactsActionError(response.data.message));
          dispatch(toggleFetchingContacts(false));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          dispatch(setContactsActionError(error.response.data.message));
        } else if (error && error.response.status === 401) {
          initializeClientToken();
          dispatch(setContactsActionError(error.response.data.detail));
        } else {
          dispatch(setContactsActionError(error.response.data.detail));
        }
        dispatch(toggleFetchingContacts(false));
      });
  };
}

// fetch an Contact
export function fetchAContactAction(num) {
  return function action(dispatch) {
    dispatch(clearContactsState);
    dispatch(toggleFetchingAContact(true));
    return ContactsClient.fetchContact(num)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setSelectedContact(response.data.data.client));
          dispatch(toggleFetchingAContact(false));
          return { status: true, message: response.data.data.client };
        } else {
          dispatch(setContactsActionError(response.data.message));
          dispatch(toggleFetchingAContact(false));
          return { status: true, message: response.data.message };
        }
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 400) {
          dispatch(setContactsActionError(error.response.data.message));
        } else if (error && error.response.status === 401) {
          initializeClientToken();
          dispatch(setContactsActionError(error.response.data.detail));
        } else {
          dispatch(setContactsActionError(error.response.data.message));
        }
        dispatch(toggleFetchingAContact(false));
        return { status: false, message: error.response.data.message };
      });
  };
}
