import moment from "moment";

export const todayDate = () => {
  const todayDate = moment().format("YYYY-MM-DD");
  return todayDate;
};

export const hyphenSeparatedDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const checkDateIsToday = (date) => {
  const todayDate = moment().format("YYYY-MM-DD");
  const dateString = moment(date).format("YYYY-MM-DD");
  return dateString === todayDate;
};

export const futureDate = (currentDate, months) => {
  const dateString = moment(currentDate).add(months, "M").format("YYYY-MM-DD");
  return dateString;
};

export const getDifferenceInMonths = (start, end) => {
  const difference = moment(start).diff(moment(end), "months");
  return difference > 0 ? difference : difference + 1;
};

export const standardDate = (dateValue) => {
  const dateString = moment(dateValue).format("DD/MM/YYYY");
  return dateString;
};

export const standardDateWithDay = (dateValue) => {
  const dateString = moment(dateValue).format("ddd, DD MMM YYYY");
  return dateString;
};

export const standardDateTime = (dateValue) => {
  const dateString = moment(dateValue).format("DD/MM/YYYY HH:mm:ss");
  return dateString;
};

export const isDateTimeInThePast = (dateValue, startDate) => {
  const start = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  const dateVal = moment(dateValue).format("YYYY-MM-DD HH:mm:ss");

  return moment(dateVal).isAfter(start);
};

export const isDateInThePast = (dateValue, startDate) => {
  const start = moment(startDate).format("YYYY-MM-DD");
  const dateVal = moment(dateValue).format("YYYY-MM-DD");

  return moment(dateVal).isBefore(start);
};

export const checkIfSame = (date1, date2) => {
  const first = moment(date1).format("YYYY-MM-DD");
  const second = moment(date2).format("YYYY-MM-DD");

  return moment(first).isSame(second);
};

export const standardDateWithOrdinal = (dateValue) => {
  const dateString = moment(dateValue).format("Do MMMM YYYY");
  return dateString;
};

export const isDateInThisWeek = (date) => {
  const start = moment().startOf("week").format("YYYY-MM-DD");
  const end = moment().endOf("week").format("YYYY-MM-DD");
  const dateString = moment(date).format("YYYY-MM-DD");
  return dateString >= start && dateString <= end; //true if dates are in the same week
};

export const isDateInThisMonth = (date) => {
  const start = moment().startOf("month").format("YYYY-MM-DD");
  const end = moment().endOf("month").format("YYYY-MM-DD");
  const dateString = moment(date).format("YYYY-MM-DD");
  return moment(dateString).isBetween(start, end); //true if dates are in the same month
};
