import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Auth from "./pages/Auth";
import Administration from "./pages/Administration";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Notifications from "./pages/Notifications";
import ProtectedRoute from "./configs/ProtectedRoute";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import SignUp from "./pages/SignUp";
import Wallet from "./pages/Wallet";
import Documents from "./pages/Documents";
import Authorization from "./pages/Authorization";
import PaymentsTransactions from "./pages/PaymentsTransactions";
import PaymentsTab from "./pages/payments";
import MoneyTransfer from "./pages/MoneyTransfer";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* all protected routed here */}

          <Route
            path="home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* Admin routes */}
          <Route
            path="admin"
            element={
              <ProtectedRoute>
                <Administration />
              </ProtectedRoute>
            }
          ></Route>

          {/* Payments Route */}
          <Route
            path="payments"
            element={
              <ProtectedRoute>
                <PaymentsTab />
              </ProtectedRoute>
            }
          ></Route>

          {/* transactions routes */}
          <Route
            path="transactions"
            element={
              <ProtectedRoute>
                <PaymentsTransactions />
              </ProtectedRoute>
            }
          ></Route>

          {/* money transfer routes */}
          <Route
            path="moneyTransfers"
            element={
              <ProtectedRoute>
                <MoneyTransfer />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="wallet"
            element={
              <ProtectedRoute>
                <Wallet />
              </ProtectedRoute>
            }
          />
        </Route>
        {/* list the public routes */}
        <Route index path="/" element={<Auth />} />
        <Route index path="/documents" element={<Documents />} />
        <Route index path="/auth" element={<Authorization />} />
        <Route index path="signup" element={<SignUp />} />
        <Route index path="terms" element={<Terms />} />
        <Route index path="privacy" element={<Privacy />} />
        <Route index path="about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
