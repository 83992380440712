export const INITIALIZE_STORE_STATE = "INITIALIZE_STORE_STATE";

export const TOGGLE_FETCHING_STORES = "TOGGLE_FETCHING_STORES";

export const SET_STORES = "SET_STORES";

export const CREATE_STORE = "CREATE_STORE";

export const UPDATE_STORE = "UPDATE_STORE";

export const TOGGLE_STORE_STATUS = "TOGGLE_STORE_STATUS";
