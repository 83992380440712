import WalletApiClient from "../../api/WalletApiClient";
import {
  CLEAR_WALLET_STATE,
  SET_SELECTED_TRANSACTION,
  SET_WALLET_TRANSACTIONS,
  TOGGLE_FETCHING_WALLET_TRANSACTIONS,
} from "./walletActionTypes";

export const clearWalletTransactionsState = (data) => {
  return {
    type: CLEAR_WALLET_STATE,
  };
};

export const setWalletTransactions = (data) => {
  return {
    type: SET_WALLET_TRANSACTIONS,
    payload: data,
  };
};

export const toggleFetchingWalletTransactions = (status) => {
  return {
    type: TOGGLE_FETCHING_WALLET_TRANSACTIONS,
    payload: status,
  };
};

export const setSelectedWalletTransaction = (data) => {
  return {
    type: SET_SELECTED_TRANSACTION,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchWalletTransactionsAction() {
  return function action(dispatch) {
    dispatch(clearWalletTransactionsState);
    dispatch(toggleFetchingWalletTransactions(true));

    return WalletApiClient.fetchWalletTransactions()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setWalletTransactions(response.data.data));
          dispatch(toggleFetchingWalletTransactions(false));
        }
      })
      .catch((error) => {
        dispatch(toggleFetchingWalletTransactions(false));
      });
  };
}

export function fetchWalletAction() {
  return function action(dispatch) {
    return WalletApiClient.fetchWallet()
      .then((response) => {
        if (response.status === 200) {
          return { status: true, data: response.data.data };
        }
      })
      .catch((error) => {
        return { status: false, data: null };
      });
  };
}

export function setSelectedWalletTransactionAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedWalletTransaction(data));
  };
}
