import NotificationsClient from "../../api/NotificationsClient";
import {
  INITIALIZE_NOTIFICATION_STATE,
  SET_NOTIFICATIONS,
  TOGGLE_FETCHING_NOTIFICATIONS,
  MARK_AS_READ,
  MARK_ALL_AS_READ,
} from "./notificationsActionTypes";

// set the actions
export const initializeNotificationsState = () => {
  return {
    type: INITIALIZE_NOTIFICATION_STATE,
  };
};

export const toggleFetchingNotifications = (status) => {
  return {
    type: TOGGLE_FETCHING_NOTIFICATIONS,
    payload: status,
  };
};

export const setNotifications = (data) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: data,
  };
};

export const markAsRead = (data) => {
  return {
    type: MARK_AS_READ,
    payload: data,
  };
};

export const markAllAsRead = (data) => {
  return {
    type: MARK_ALL_AS_READ,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchNotificationsAction(limit, page) {
  return function action(dispatch) {
    dispatch(initializeNotificationsState);
    dispatch(toggleFetchingNotifications(true));

    return NotificationsClient.fetchNotifications(limit, page)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setNotifications(response.data.data));
          dispatch(toggleFetchingNotifications(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingNotifications(false));
      });
  };
}

export function markAsReadAction(data) {
  return function action(dispatch) {
    return NotificationsClient.markAsRead(data)
      .then((response) => {
        console.log(response.data.data);
        if (response.status === 200) {
          dispatch(markAsRead(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  };
}

export function markAllAsReadAction() {
  return function action(dispatch) {
    return NotificationsClient.markAllAsRead()
      .then((response) => {
        console.log(response.data.data);
        if (response.status === 200) {
          dispatch(markAllAsRead(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  };
}
