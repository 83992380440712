import {
  CREATE_EMPLOYEE,
  INITIALIZE_EMPLOYEE_STATE,
  SET_EMPLOYEES,
  TOGGLE_FETCHING_EMPLOYEES,
  TOGGLE_EMPLOYEE_STATUS,
  UPDATE_EMPLOYEE,
} from "./employeesActionTypes";

// set the initial state
const initialState = () => {
  return {
    fetchingEmployees: false,
    employees: [],
  };
};

// set the reducer
const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_EMPLOYEE_STATE:
      return {
        ...state,
      };
    /* falls through */
    case TOGGLE_FETCHING_EMPLOYEES:
      return {
        ...state,
        fetchingEmployees: action.payload,
      };
    /* falls through */
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    /* falls through */
    case CREATE_EMPLOYEE:
      return {
        ...state,
        employees: [...state.employees, action.payload],
      };
    /* falls through */
    case UPDATE_EMPLOYEE:
      let newemployees = [...state.employees];
      let foundIndex = newemployees?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newemployees[foundIndex] = action.payload;
      return {
        ...state,
        employees: newemployees,
      };
    /* falls through */

    case TOGGLE_EMPLOYEE_STATUS:
      return {
        ...state,
        employees: state.employees?.map((item) => {
          if (item.id === action.payload?.id) {
            return {
              ...item,
              status: action.payload.status,
            };
          }
          return item;
        }),
      };
    /* falls through */

    default:
      return state;
  }
};

export default employeesReducer;
