/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  fetchAnalysis(id) {
    return apiClient.get(`/dashboard/analysis?store_id=${id}`);
  },
  fetchMonthlyRevenue() {
    return apiClient.get(`/dashboard/analysis/revenue`);
  },
  fetchTransactions() {
    return apiClient.get(`/dashboard/analysis/transactions/count`);
  },
};
