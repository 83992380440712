export const CLEAR_CONTACTS_STATE = "CLEAR_CONTACTS_STATE";

export const TOGGLE_FETCHING_CONTACTS = "TOGGLE_FETCHING_CONTACTS";

export const TOGGLE_FETCHING_A_CONTACT = "TOGGLE_FETCHING_A_CONTACT";

export const SET_CONTACTS = "SET_CONTACTS";

export const SET_SELECTED_CONTACT = "SET_SELECTED_CONTACT";

export const SET_CONTACTS_ACTION_SUCCESS = "SET_CONTACTS_ACTION_SUCCESS";

export const SET_CONTACTS_ACTION_ERROR = "SET_CONTACTS_ACTION_ERROR";
