import React, { useEffect, useState } from "react";
import Loader from "../components/global/Loader";
import DataTable from "../components/datatable/DataTable";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import DateTimeFormat from "../components/global/DateTimeFormat";
import { useDispatch, useSelector } from "react-redux";
import HeaderText from "../components/global/HeaderText";
import { fetchWalletTransactionsAction } from "../redux/Index";

function Wallet() {
  const dispatch = useDispatch();
  const walletState = useSelector((state) => state.wallet);
  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    setWallet(walletState?.walletTransactions);
  }, [walletState?.walletTransactions]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = () => {
    dispatch(fetchWalletTransactionsAction());
  };

  const headers = ["Amount available", "Maximum float", "Last update"];
  let tableRows = [];

  if (wallet?.length) {
    for (let i = 0; i < wallet?.length; i++) {
      const element = wallet[i];
      tableRows.push({
        amount: element.amount ? (
          <CurrencyFormatter value={element.amount} />
        ) : (
          "0.00"
        ),
        float: element.maximum_float ? (
          <CurrencyFormatter value={element.maximum_float} />
        ) : (
          "0.00"
        ),
        updated_at: <DateTimeFormat>{element.updated_at}</DateTimeFormat>,
      });
    }
  }

  return (
    <div>
      {/* header information */}
      <HeaderText title="Wallet" />
      {walletState?.fetchingWalletTransactions ? (
        <Loader />
      ) : (
        <DataTable headers={headers} data={tableRows} />
      )}
    </div>
  );
}

export default Wallet;
