import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentsAction } from "../../redux/Index";
import CurrencyFormatter from "../../components/global/CurrencyFormatter";
import { standardDateTime } from "../../components/global/DateUtilities copy";
import Loader from "../../components/global/Loader";
import ApiDataTable from "../../components/datatable/ApiDataTable";

const Payments = () => {
  const dispatch = useDispatch();
  const paymentState = useSelector((state) => state.payments);

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(paymentState?.payments);
  }, [paymentState, paymentState?.payments]);

  useEffect(() => {
    loadList(10, 1);
    // eslint-disable-next-line
  }, []);

  const loadList = (limit, page) => {
    dispatch(fetchPaymentsAction(limit, page));
  };

  const headers = [
    "Ref",
    "Client",
    "Client Number",
    "Amount Paid",
    "Paygram fees",
    "Transaction Date",
  ];
  let tableRows = [];

  if (list?.data?.length) {
    for (let i = 0; i < list?.data?.length; i++) {
      const element = list?.data[i];

      tableRows.push({
        ref: element?.ref ?? "",
        client: element?.payer.full_name ?? "",
        client_number: element?.payer.phone_number ?? "",
        amount: <CurrencyFormatter value={parseInt(element.amount ?? 0)} />,
        paygram_fees: (
          <CurrencyFormatter value={parseInt(element.paygram_fees ?? 0)} />
        ),
        created_at: standardDateTime(element.created_at),
      });
    }
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-end my-2">
        <p className="text-gray-700 text-sm">Total amount payed:&nbsp; </p>
        <CurrencyFormatter value={list?.total_transfers ?? 0} />
      </div>
      {paymentState?.fetchingPayments ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={loadList}
          totalPageCount={list?.last_page}
        />
      )}
    </div>
  );
};

export default Payments;
