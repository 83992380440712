import DashboardClient from "../../api/DashboardClient";
import {
  SET_ANALYSIS,
  SET_MONTHLY_REVENUE,
  SET_TRANSACTIONS,
  TOGGLE_FETCHING_ANALYSIS,
  TOGGLE_FETCHING_MONTHLY_REVENUE,
  TOGGLE_FETCHING_TRANSACTIONS,
} from "./dashboardActionTypes";

export const toggleDashboardFetchingAnalysis = (status) => {
  return {
    type: TOGGLE_FETCHING_ANALYSIS,
    payload: status,
  };
};

export const setDashboardAnalysis = (data) => {
  return {
    type: SET_ANALYSIS,
    payload: data,
  };
};

export const toggleFetchingMonthlyRevenue = (status) => {
  return {
    type: TOGGLE_FETCHING_MONTHLY_REVENUE,
    payload: status,
  };
};

export const setMonthlyRevenue = (data) => {
  return {
    type: SET_MONTHLY_REVENUE,
    payload: data,
  };
};

export const toggleFetchingTransactionsSummary = (status) => {
  return {
    type: TOGGLE_FETCHING_TRANSACTIONS,
    payload: status,
  };
};

export const setTransactionsSummary = (data) => {
  return {
    type: SET_TRANSACTIONS,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchingDashboardAnalysisAction() {
  return function action(dispatch, getState) {
    const { userInfo } = getState().auth;
    dispatch(toggleDashboardFetchingAnalysis(true));

    return DashboardClient.fetchAnalysis(userInfo?.id)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setDashboardAnalysis(response.data.data));
          dispatch(toggleDashboardFetchingAnalysis(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleDashboardFetchingAnalysis(false));
      });
  };
}

export function fetchMonthlyRevenueAction() {
  return function action(dispatch) {
    dispatch(toggleFetchingMonthlyRevenue(true));

    return DashboardClient.fetchMonthlyRevenue()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setMonthlyRevenue(response.data.data));
          dispatch(toggleFetchingMonthlyRevenue(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingMonthlyRevenue(false));
      });
  };
}

export function fetchTransactionsSummaryAction() {
  return function action(dispatch) {
    dispatch(toggleFetchingTransactionsSummary(true));

    return DashboardClient.fetchTransactions()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setTransactionsSummary(response.data.data));
          dispatch(toggleFetchingTransactionsSummary(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingTransactionsSummary(false));
      });
  };
}
