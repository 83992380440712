import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <ul className={classnames("flex items-center", { [className]: className })}>
      <li
        className={`${
          currentPage === 1 ? "hidden" : "cursor-pointer text-3xl text-gray-500"
        }`}
        onClick={onPrevious}
      >
        <i className="bx bx-chevron-left" />
      </li>
      {paginationRange?.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className="text-lg text-gray-600">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={`${
              pageNumber === currentPage
                ? "bg-primary flex items-center justify-center p-1 w-6 h-6 text-[13px] rounded-full text-white"
                : "cursor-pointer"
            }`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`${
          currentPage === lastPage
            ? "hidden"
            : "cursor-pointer text-3xl text-gray-500"
        }`}
        onClick={onNext}
      >
        <i className="bx bx-chevron-right " />
      </li>
    </ul>
  );
};

export default Pagination;
