import React, { useEffect, useState } from "react";
import Loader from "../components/global/Loader";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import HeaderText from "../components/global/HeaderText";
import {
  fetchMoneyTransfersAction,
  transferToMpesaAction,
  transferToPayGramAction,
} from "../redux/Index";
import Button from "../components/global/Button";
import TextInput from "../components/global/TextInput";
import FabButton from "../components/global/FabButton";
import { ToastContainer, toast } from "react-toastify";
import ApiDataTable from "../components/datatable/ApiDataTable";
import { standardDateTime } from "../components/global/DateUtilities";
import GlobalModal from "../components/global/GlobalModal";
import SelectInput from "../components/global/SelectInput";

function MoneyTransfer() {
  const dispatch = useDispatch();
  const moneyTransfersState = useSelector((state) => state.transactions);

  const userLocation = useSelector((state) => state.auth?.userLocation);
  const [list, setList] = useState(null);

  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleGlobalModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    setList(moneyTransfersState?.moneyTransfers);
  }, [moneyTransfersState?.moneyTransfers]);

  useEffect(() => {
    loadList(10, 1);
    // eslint-disable-next-line
  }, []);

  const loadList = (limit, page) => {
    dispatch(fetchMoneyTransfersAction(limit, page));
  };

  // FORM OPERATIONS

  const [inputValues, setInputValues] = useState({
    transfer_option: 0,
    amount: 0,
    phone_number: "",
  });

  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleSelectChange = (name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    const mpesaData = {
      phone_number: inputValues.phone_number,
      amount: Number(inputValues.amount),
      withdrawal_latitude: userLocation?.latitude?.toFixed(6),
      withdrawal_longitude: userLocation?.longitude?.toFixed(6),
    };

    const payGramData = {
      amount: Number(inputValues.amount),
      withdrawal_latitude: userLocation?.latitude?.toFixed(6),
      withdrawal_longitude: userLocation?.longitude?.toFixed(6),
    };

    if (inputValues.amount && inputValues.transfer_option) {
      dispatch(
        inputValues.transfer_option > 1
          ? transferToPayGramAction(payGramData)
          : transferToMpesaAction(mpesaData)
      ).then((status) => {
        if (status) {
          setIsSubmit(false);
          toggleGlobalModal();
          toast.success("Money has beeen transferred");
        } else {
          setIsSubmit(false);
          toast.error("Failed to execute the request");
        }
      });
    } else {
      setIsSubmit(false);
      toast.error("Fill in the form correctly");
    }
  };

  const fabIconClick = () => {
    toggleGlobalModal();
    setInputValues({
      payer: "",
      amount: "",
    });
    setIsSubmit(false);
  };

  // DISPLAY TABLE

  const headers = [
    "Transaction ID",
    "Type",
    "Amount",
    "Paygram fees",
    "Transaction date",
  ];
  let tableRows = [];

  if (list?.data?.length) {
    for (let i = 0; i < list?.data?.length; i++) {
      const element = list?.data[i];
      tableRows.push({
        transactionID: element?.ref ?? "",
        type: element?.type_display ?? "",
        amount: <CurrencyFormatter value={parseInt(element.amount)} />,
        paygram_fees: (
          <CurrencyFormatter value={parseInt(element.paygram_fees ?? 0)} />
        ),
        transaction_date: standardDateTime(element.created_at),
      });
    }
  }

  const transferOptions = [
    { label: "Transfer to Mpesa", value: 1 },
    { label: "Transfer to PayGram", value: 2 },
  ];

  const exportReport = () => {
    // setIsSubmit(true);
    // dispatch(exportTransactionsReport("pdf")).then((status) => {
    //   if (status) {
    //     setIsSubmit(false);
    //     toast.success("Your report has been emailed to you.");
    //   } else {
    //     setIsSubmit(false);
    //     toast.error("Failed to process your request.");
    //   }
    // });
  };

  return (
    <div className="flex-1 h-full">
      <FabButton
        onPressAction={fabIconClick}
        text="Transfer money"
        icon="bx bx-transfer"
      />
      {/* header information */}
      <div className="flex flex-row items-center justify-between">
        <HeaderText title="Money Transfer" />

        {/* display the total for Money Transfer */}
        <div className="flex flex-row items-center justify-between space-x-4">
          <div className="flex flex-row items-center justify-end mb-4 space-x-4">
            <div className="flex flex-row items-center">
              <p className="text-gray-700 text-sm">
                Total amount transferred:&nbsp;{" "}
              </p>
              <CurrencyFormatter value={list?.total_transfers ?? 0} />
            </div>
            <button
              type="button"
              onClick={exportReport}
              className="text-white sm:text-xs font-bold text-sm bg-primary px-4 h-8 rounded-md flex flex-row items-center"
            >
              <i class="bx bxs-file-pdf text-lg"></i>
              <p className="sm:hidden">Export PDF</p>
            </button>
          </div>
        </div>
      </div>
      {moneyTransfersState?.fetchingMoneyTransfers ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={loadList}
          totalPageCount={list?.last_page}
        />
      )}

      <GlobalModal modalOpen={modalOpen} handleClose={toggleGlobalModal}>
        <p className="text-gray-600 text-center text-lg font-semibold mb-4 border-b border-gray-200 pb-2">
          Transfer money
        </p>

        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <div>
            <SelectInput
              label="Transfer Option"
              onChange={handleSelectChange}
              options={transferOptions}
              name="transfer_option"
              value={inputValues?.transfer_option}
            />
            {inputValues.transfer_option && (
              <div>
                {inputValues.transfer_option < 2 ? (
                  <TextInput
                    required="required"
                    label="Phone Number"
                    name="phone_number"
                    type="numeric"
                    value={inputValues.phone_number}
                    onChange={handleChange}
                  />
                ) : null}

                <TextInput
                  required="required"
                  label="Amount"
                  name="amount"
                  type="numeric"
                  value={inputValues.amount}
                  onChange={handleChange}
                />
              </div>
            )}

            <Button
              text={isSubmit ? "Processing..." : "Transfer"}
              disabled={isSubmit}
            />
          </div>
        </form>
      </GlobalModal>

      <ToastContainer />
    </div>
  );
}

export default MoneyTransfer;
