import {
  SET_ANALYSIS,
  SET_MONTHLY_REVENUE,
  SET_TRANSACTIONS,
  TOGGLE_FETCHING_ANALYSIS,
  TOGGLE_FETCHING_MONTHLY_REVENUE,
  TOGGLE_FETCHING_TRANSACTIONS,
} from "./dashboardActionTypes";

// set the initial state
const initialState = () => {
  return {
    fetchingDashboardAnalysis: false,
    dashboardAnalysis: [],
    fetchingMonthlyRevenue: false,
    monthlyRevenue: [],
    fetchingTransactions: false,
    transactions: [],
  };
};

// set the reducer
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FETCHING_ANALYSIS:
      return {
        ...state,
        fetchingDashboardAnalysis: action.payload,
      };
    case SET_ANALYSIS:
      return {
        ...state,
        dashboardAnalysis: action.payload,
      };

    case TOGGLE_FETCHING_MONTHLY_REVENUE:
      return {
        ...state,
        fetchingMonthlyRevenue: action.payload,
      };
    case SET_MONTHLY_REVENUE:
      return {
        ...state,
        monthlyRevenue: action.payload,
      };

    case TOGGLE_FETCHING_TRANSACTIONS:
      return {
        ...state,
        fetchingTransactions: action.payload,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
