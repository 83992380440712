import React from "react";

function HeaderText({ title }) {
  return (
    <div className="mt-1 mb-3">
      <h1 className="text-primary font-semibold text-xl md:text-2xl sm:text-sm">
        {title}
      </h1>
    </div>
  );
}

export default HeaderText;
