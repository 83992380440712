import {
  SET_DOCUMENTS,
  SET_SELECTED_ACCOUNT,
  TOGGLE_FETCHING_DOCUMENTS,
  UPLOAD_DOCUMENTS,
} from "./documentsActionTypes";

// set the initial state
const initialState = () => {
  return {
    fetchingDocuments: false,
    documents: [],
    selectedAccount: {},
  };
};

// set the reducer
const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FETCHING_DOCUMENTS:
      return {
        ...state,
        fetchingDocuments: action.payload,
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case UPLOAD_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    /* falls through */
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default documentsReducer;
